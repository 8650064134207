
.container {
  padding: 20px 0;
}

.simple-card {
  margin-top: 40px;
  background: #fff;
  border-radius: 10px;
  padding: 20px 30px;
}
